import React, { Component } from 'react'
import { Container, Col, Row, Card, Input, Label, FormGroup, Button, Modal, ModalBody, InputGroup, Spinner } from 'reactstrap'
import axios from 'axios'
import FormData from 'form-data'
import { navigate } from 'gatsby'
import { MdLocationOn } from 'react-icons/md'
import { FiRefreshCw, FiSearch, FiUpload, FiSend } from 'react-icons/fi'

import './index.scss'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

class CreatePage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			authorId: null,
			body: '',
			orgName: 'Where is your gratitude going?',
			orgLocation: 'Click to find hospitals, clinics, etc...',
			addressedTo: '',
			organizationId: null,
			artistName: null,
			artistHandle: null,
			photoUrl: this.getRandomUnsplash(),
			photoId: null,
			orgs: [],
			file: null,
			fileUrl: null,
			locationModal: false,
			galleryModal: false,
			submitted: false,
			gallery: [],
			searchTerm: '',
			isLoading: false,
		}
		this.handleRecipientChange = this.handleRecipientChange.bind(this)
		this.handleBodyChange = this.handleBodyChange.bind(this)
		this.toggleGalleryModal = this.toggleGalleryModal.bind(this)
		this.toggleLocationModal = this.toggleLocationModal.bind(this)
	}

	async componentDidMount() {
		const hash = new URLSearchParams(window.location.search).get('hashtag')
		this.setState({
			body: hash ? `#${hash}` : '',
		})

		const org = new URLSearchParams(window.location.search).get('org')
		if (org) {
			const orgResponse = await axios.get(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/orgs/id/${org}`)

			this.setState({
				orgName: orgResponse.data.orgs[0].name,
				orgLocation: `${orgResponse.data.orgs[0].city}, ${orgResponse.data.orgs[0].state}`,
				organizationId: orgResponse.data.orgs[0].id,
			})
		}
	}

	async getRandomUnsplash() {
		try {
			const { data } = await axios.get(
				`${process.env.UNSPLASH_API_URL}/photos/random/?client_id=${process.env.UNSPLASH_ACCESS_KEY}&query=${process.env.UNSPLASH_RANDOM_QUERY}`
			)
			this.setState({
				photoUrl: data.urls.small,
				photoId: data.id,
				artistName: data.user.name,
				artistHandle: data.user.username,
				fileUrl: data.urls.small,
				file: null,
			})
		} catch (error) {
			console.error('Error retrieving random unsplash: ', error)
		}
	}

	async submitForm(e) {
		e.preventDefault()
		this.setState({
			submitted: true,
			isLoading: true,
		})
		const userId = window.localStorage.getItem('userId')

		const data = {
			authorId: userId ? userId : null,
			body: this.state.body,
			orgName: this.state.orgName,
			orgLocation: this.state.orgLocation,
			addressedTo: this.state.addressedTo,
			organizationId: this.state.organizationId,
			artistName: this.state.artistName,
			artistHandle: this.state.artistHandle,
			photoUrl: this.state.photoUrl,
			photoId: this.state.photoId,
		}

		const formData = new FormData()

		for (const key in data) {
			if (data[key] != null) {
				formData.append(key, data[key])
			}
		}
		if (this.state.file) {
			formData.append('file', this.state.file)
		}

		try {
			await axios
				.post(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/cpcs/new`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data; boundary=e87Yh39612345;',
					},
				})
				.then(res => {
					// store cpcId so we can pass it back to API to update post with user (author) info..TS
					if (res.data.cpcId) window.localStorage.setItem('cpcId', res.data.cpcId)
					if (userId) {
						navigate('/profile')
					} else {
						navigate('/login/register')
					}
				})
		} catch (error) {
			console.error('Error creating CPC: ', error)
		}
	}

	async handleBodyChange(e) {
		this.setState({
			body: e.target.value,
		})
	}

	async handleRecipientChange(e) {
		this.setState({
			addressedTo: e.target.value,
		})
	}

	async insertTag(e) {
		if (!this.state.body.includes(e) || e === '#') {
			this.setState({
				body: this.state.body + ` ${e}`,
				// body: this.state.body.replace(/(^|\W)(#[a-z\d][\w-]*)/ig, '') + ` ${e}`
			})
		}
	}

	async toggleLocationModal() {
		if (this.state.locationModal === false) {
			await this.getFeaturedOrgs()
		}
		this.setState({
			locationModal: !this.state.locationModal,
		})
	}

	async getFeaturedOrgs() {
		try {
			const { data } = await axios.get(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/orgs/featured`)
			this.setState({
				orgs: data.orgs,
			})
		} catch (error) {
			console.error('Error retrieving featured orgs: ', error)
		}
	}

	async setLocationInfo(e) {
		const { orgs } = this.state
		this.setState({
			orgName: orgs[e].name,
			orgLocation: `${orgs[e].city}, ${orgs[e].state}`,
			organizationId: orgs[e].id,
			locationModal: false,
		})
	}

	async handleLocationSearch(e) {
		if (e.target.value.length > 2) {
			try {
				const { data } = await axios.get(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/orgs/search/${e.target.value}`)
				this.setState({
					orgs: data.results,
				})
			} catch (error) {
				console.error('Error retrieving orgs for location search: ', error)
			}
		} else if (e.target.value.length === 0) {
			this.getFeaturedOrgs()
		}
	}

	onChangeHandler = event => {
		this.setState({
			fileUrl: URL.createObjectURL(event.target.files[0]),
			file: event.target.files[0],
			photoUrl: null,
			photoId: null,
			artistName: null,
			artistHandle: null,
		})
	}

	async toggleGalleryModal() {
		if (this.state.galleryModal === false) {
			await this.getGalleryRandom()
		}
		this.setState({
			galleryModal: !this.state.galleryModal,
		})
	}

	async setGalleryPhoto(e) {
		const { gallery } = this.state
		this.setState({
			photoUrl: gallery[e].urls.small,
			photoId: gallery[e].id,
			artistName: gallery[e].user.name,
			artistHandle: gallery[e].user.username,
			fileUrl: gallery[e].urls.small,
			file: null,
			galleryModal: false,
		})
	}

	async getGalleryRandom() {
		try {
			const { data } = await axios.get(
				`${process.env.UNSPLASH_API_URL}/search/photos/?client_id=${process.env.UNSPLASH_ACCESS_KEY}&query=${process.env.UNSPLASH_RANDOM_QUERY}&per_page=18&order_by=latest`
			)
			let images = []
			for (const image in data.results) {
				images.push(data.results[image])
			}
			this.setState({
				gallery: images,
			})
		} catch (error) {
			console.error('Error retrieving random unsplash: ', error)
		}
	}

	async changeSearchTerm(e) {
		this.setState({
			searchTerm: e.target.value,
		})
	}

	async handleGallerySearch() {
		try {
			const { data } = await axios.get(
				`${process.env.UNSPLASH_API_URL}/search/photos/?client_id=${process.env.UNSPLASH_ACCESS_KEY}&query=${this.state.searchTerm}&per_page=18&order_by=relevant`
			)
			let images = []
			for (const image in data.results) {
				images.push(data.results[image])
			}
			this.setState({
				gallery: images,
			})
		} catch (error) {
			console.error('Error retrieving unsplash gallery: ', error)
		}
	}

	render() {
		const { body, addressedTo, orgName, orgLocation, orgs, organizationId, artistName, artistHandle, submitted, fileUrl, gallery } = this.state
		return (
			<Layout>
				<SEO title='Create' />
				<Container className='pb-5'>
					<Row>
						<Col lg={2}></Col>
						<Col lg={6}>
							<Row className='justify-content-end'>
								<Card className='create-card'>
									<Col>
										<Row>
											<Col>
												<Row>
													<img src={fileUrl} alt='' className='create-card-image' />
													{artistName && (
														<span className='create-card-image-caption'>
															Photo by{' '}
															<a
																href={`https://www.unsplash.com/${artistHandle}?utm_source=carepostcard&utm_medium=referral`}
																target='_blank'
																rel='noopener noreferrer'
															>
																{artistName}
															</a>{' '}
															on{' '}
															<a
																href='https://www.unsplash.com?utm_source=carepostcard&utm_medium=referral'
																target='_blank'
																rel='noopener noreferrer'
															>
																Unsplash
															</a>
														</span>
													)}
												</Row>
												<Row className='create-card-options'>
													<Col>
														<Button className='create-card-options-button' onClick={() => this.getRandomUnsplash()}>
															<FiRefreshCw className='create-card-options-icon mr-1' />
															Random
														</Button>
													</Col>
													<Col>
														<Button className='create-card-options-button' onClick={() => this.toggleGalleryModal()}>
															<FiSearch className='create-card-options-icon mr-1' />
															Gallery
														</Button>
													</Col>
													<Col>
														<Button className='create-card-options-button'>
															<Label for='myfile' style={{ cursor: 'pointer' }}>
																<FiUpload className='create-card-options-icon mr-1' />
																Upload
															</Label>
														</Button>
														<Input hidden type='file' id='myfile' name='myfile' onChange={this.onChangeHandler} />
													</Col>
												</Row>
											</Col>
										</Row>
										<Row className='create-card-body'>
											<Row className='pr-2 mr-2'>
												<Col xs={1}>
													<MdLocationOn className='create-card-map-icon ml-3' />
												</Col>
												<Col xs={10}>
													<Button onClick={() => this.toggleLocationModal()} className='create-card-location pt-0 ml-3'>
														<Row className='create-card-title px-3'>{orgName}</Row>
														<Row className='create-card-subtitle px-3'>{orgLocation}</Row>
													</Button>
												</Col>
											</Row>
											<FormGroup row={true} className='text-left px-4 pt-2'>
												<Col className='d-flex'>
													<Label className='pt-2 '>To:</Label>
													<Input
														type='text'
														name='addressedTo'
														id='addressedTo'
														placeholder='Recipient&#39;s Name (optional)'
														className='create-card-recipient ml-2 pt-2'
														value={addressedTo}
														onChange={e => {
															this.handleRecipientChange(e)
														}}
													/>
												</Col>
											</FormGroup>
											<FormGroup row={true} className='text-left px-4'>
												<Input
													type='textarea'
													name='body'
													id='body'
													placeholder='Tell the world about the moment that impacted you! Your Carepostcard can shine a light on a hospital&#39;s actions, a nurse&#39;s smile, or any story that showcases your gratitude for healthcare workers.'
													className='create-card-textarea'
													value={body}
													onChange={e => {
														this.handleBodyChange(e)
													}}
												/>
											</FormGroup>
										</Row>
										<Row className='create-card-hashtags justify-content-center py-3'>
											<Button value='#' onClick={e => this.insertTag(e.target.value)} className='create-card-hashtags-button'>
												Add hashtag
											</Button>
											<Button
												value='#thankanurse'
												onClick={e => this.insertTag(e.target.value)}
												className='create-card-hashtags-button'
											>
												#thankanurse
											</Button>
											<Button
												value='#thankadoctor'
												onClick={e => this.insertTag(e.target.value)}
												className='create-card-hashtags-button'
											>
												#thankadoctor
											</Button>
										</Row>
									</Col>
								</Card>
							</Row>
						</Col>
						<Col lg={2} className='text-left send-column'>
							<Row>
								<Button
									disabled={!organizationId || !body || submitted}
									onClick={e => this.submitForm(e)}
									className='create-card-send-button'
								>
									{this.state.isLoading ? (
										<Spinner animation='grow' role='status' style={{ color: '#ff0066', margin: '1px 0 0 -3px' }} />
									) : (
										<FiSend className='create-card-send-icon' />
									)}
								</Button>
							</Row>
							<Row>
								<span
									style={!organizationId || !body || submitted ? { color: '#828282' } : { color: '#ff0066' }}
									className='send-text'
								>
									Send
								</span>
							</Row>
						</Col>
						<Col lg={2}></Col>
					</Row>

					<Modal
						isOpen={this.state.locationModal}
						toggle={this.toggleLocationModal}
						centered={true}
						backdrop={true}
						keyboard={true}
						autoFocus={false}
					>
						<ModalBody>
							<Row>
								<InputGroup row='true' className='input-group text-left d-flex'>
									<Input
										autoFocus={true}
										type='text'
										placeholder='Search...'
										className='input-text'
										onChange={e => {
											this.handleLocationSearch(e)
										}}
									/>
									{/* <Button className='search-icon-button'>
										<FiSearch />
									</Button> */}
								</InputGroup>
							</Row>
							<Row className='create-card-location-body'>
								{orgs.length ? (
									orgs.map((_, index) => {
										return (
											<Row key={index}>
												<Button onClick={() => this.setLocationInfo(index)} className='py-3 mx-1 create-card-location-button'>
													<Row className='create-card-location-title px-4'>{_.name}</Row>
													<Row className='create-card-location-subtitle px-4'>{`${_.city}, ${_.state}`}</Row>
												</Button>
											</Row>
										)
									})
								) : (
									<Row className='create-card-location-title px-4 pb-3'>No results found.</Row>
								)}
							</Row>
						</ModalBody>
					</Modal>

					<Modal
						isOpen={this.state.galleryModal}
						toggle={this.toggleGalleryModal}
						centered={true}
						backdrop={true}
						keyboard={true}
						size='lg'
						className='create-card-gallery-modal'
						autoFocus={false}
					>
						<ModalBody>
							<Row>
								<InputGroup row='true' className='input-group text-left d-flex'>
									<Input
										autoFocus={true}
										type='text'
										placeholder='Search...'
										className='input-text'
										onChange={e => this.changeSearchTerm(e)}
										onKeyPress={e => {
											if (e.key === 'Enter') {
												this.handleGallerySearch()
											}
										}}
									/>
									<Button
										className='search-icon-button'
										onClick={() => {
											this.handleGallerySearch()
										}}
									>
										<FiSearch />
									</Button>
								</InputGroup>
							</Row>
							<Row className='create-card-gallery-body'>
								{gallery.length ? (
									gallery.map((_, index) => {
										return (
											<Row key={index} className=''>
												<Button onClick={() => this.setGalleryPhoto(index)} className='create-card-gallery-button'>
													<img src={_.urls.small} alt='' className='create-card-gallery-image' />
												</Button>
											</Row>
										)
									})
								) : (
									<Row className='create-card-location-title px-4 pb-3'>No results found.</Row>
								)}
							</Row>
						</ModalBody>
					</Modal>
				</Container>
			</Layout>
		)
	}
}

export default CreatePage
